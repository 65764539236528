import { ReQuestionPositionData } from '../models/re.question.position.data';
import { BuildQuestion, OpenInterval } from './matrix';
import { PositionAndQuestion } from './position.and.question';
import { RandomizeUtil } from './randomize.util';

export class RandomPositionAndQuestion implements PositionAndQuestion {
  public getPositionAndQuestion(questions: BuildQuestion[], intervals: OpenInterval[]) {
    const randomQuestions = RandomizeUtil.shuffle([...questions]);
    for (const question of randomQuestions) {
      const positionForQuestion = this.getPositionForQuestion(question, intervals);
      if (positionForQuestion) {
        return positionForQuestion;
      }
    }
    return null;
  }
  public getPositionForQuestion(question: BuildQuestion, intervals: OpenInterval[]) {
    const randomIntervals = RandomizeUtil.shuffle([...intervals]);
    for (const interval of randomIntervals) {
      if (interval.canAddQuestion(question)) {
        return this.getQuestionPositionData(question, interval);
      }
    }
    return null;
  }
  private getQuestionPositionData(question: BuildQuestion, interval: OpenInterval): ReQuestionPositionData {
    const crossings = interval.getCrossings(question);
    return {
      question,
      position: interval.interval,
      points: question.score.perCrossing * crossings + question.answer.length * question.score.perChar,
    };
  }
}
