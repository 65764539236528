import { ChatMessage } from '../data/chat.message';
import { CrosswordGameNotification } from '../data/crossword.game.notification';
import { GameState } from '../data/game.state';
import { GameType } from '../data/game.type';
import { LanguageCode } from '../data/language';
import { PlayerTurn } from '../data/player.turn';
import { Point } from '../data/point';
import { PlayerInfo } from './../data/player.score';
import { ICrossword } from './crossword';
import { IModel } from './model';
import { IPlayer } from './player';

export interface AddCrosswordGameTurn {
  playerId: string;
  gameId: string;
  tilesSet: Point[];
}

export interface IAddChatMessage {
  gameId: any;
  chatMessage: ChatMessage;
}

export interface StartCrosswordGame {
  gameId: any;
  player: IPlayer;
}

export interface StopCrosswordGame {
  gameId: any;
  player: IPlayer;
}

export interface FindCrosswordGame {
  joinCode: number;
}

export interface JoinCrosswordGame {
  gameId: any;
  player: IPlayer;
  playerInfo: PlayerInfo;
}

export interface LeaveCrosswordGame {
  gameId: any;
  player: IPlayer;
}

export interface ICreateCrosswordGame {
  languageCode: LanguageCode;
  player: IPlayer;
  crossword: ICrossword;
}

export enum GamePlayerColor {
  Default,
  PlayerColor1,
  PlayerColor2,
  PlayerColor3,
  PlayerColor4,
}

export const SelectableGamePlayerColors: GamePlayerColor[] = [
  GamePlayerColor.PlayerColor1,
  GamePlayerColor.PlayerColor2,
  GamePlayerColor.PlayerColor3,
  GamePlayerColor.PlayerColor4,
];

export interface PlayerIdPlayerColor {
  playerId: string;
}
export interface CrosswordGameInitial {
  joinCode: number;
  languageCode: LanguageCode;
  crossword: ICrossword;
  createdByPlayerId: string;
  type?: number;
}
export interface CrosswordGameTurns {
  currentPlayerId: string;
  playerTurns: PlayerTurn[];
  turn: number;
  totalNumberOfTurns: number;
}
export interface CrosswordGamePlayersInfo {
  players: IPlayer[];
  inactivePlayers: IPlayer[];
  playerInfo: PlayerInfo[];
}
export interface CrosswordGameChats {
  chatMessages: ChatMessage[];
}
export interface CrosswordGameNotifications {
  notifications: CrosswordGameNotification[];
}
export interface CrosswordGameGlobal {
  state: GameState;
  published: boolean;
  type: GameType;
}
export interface CrosswordGameReminder {
  changedCurrentPlayerTimestamp?: number;
  remindedOnTimestamp?: number;
}
export interface CrosswordGameVersion {
  turns: number;
  playersInfo: number;
  chats: number;
  notifications: number;
  global: number;
  reminder: number;
}
export interface CrosswordGameUpdate {
  _id: any;
  turns?: CrosswordGameTurns;
  playersInfo?: CrosswordGamePlayersInfo;
  chats?: CrosswordGameChats;
  notifications?: CrosswordGameNotifications;
  global?: CrosswordGameGlobal;
  reminder?: CrosswordGameReminder;
  gameVersion: CrosswordGameVersion;
}
export interface ICrosswordGame extends IModel {
  initial: CrosswordGameInitial;
  turns: CrosswordGameTurns;
  playersInfo: CrosswordGamePlayersInfo;
  chats: CrosswordGameChats;
  notifications: CrosswordGameNotifications;
  global: CrosswordGameGlobal;
  reminder: CrosswordGameReminder;
  version: CrosswordGameVersion;
}
