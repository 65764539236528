@if (initialized) {
  <div class="flex-column stretch-min-max">
    @if (isAuthenticated()) {
      <app-connection-bar #connectionBar> </app-connection-bar>
      <app-notification-bar #notificationBar> </app-notification-bar>
      <app-navigation-bar (toggleSidenav)="navigationSidenav.toggle()"> </app-navigation-bar>
      @if (communicationService.showCrosswordGameActions | async) {
        <div
          [ngClass]="{ 'sticky-to-top': !sideNavigationShowing, 'in-background': sideNavigationShowing, 'padd-5-px': true }"
          style="display: inline-block"
        >
          <app-crossword-actions></app-crossword-actions>
        </div>
      }
    }
    <mat-sidenav-container #sideNavigationContainer class="flex-column flex-stretch">
      <mat-sidenav
        #navigationSidenav
        mode="over"
        role="navigation"
        (closed)="sideNavigationShowing = false"
        (openedStart)="sideNavigationShowing = true"
      >
        <mat-nav-list>
          <ng-container>
            @for (item of topActions; track item) {
              <a [id]="item.id" mat-list-item (click)="[navigationSidenav.close(), item.action()]" routerLinkActive="list-item-active">
                <mat-icon matListIcon class="nav-list-icon"> {{ item.icon }} </mat-icon>
                {{ item.title }}
              </a>
            }
            <mat-divider></mat-divider>
            @for (item of bottomActions; track item) {
              <a [id]="item.id" mat-list-item (click)="[navigationSidenav.close(), item.action()]" routerLinkActive="list-item-active">
                <mat-icon matListIcon class="nav-list-icon"> {{ item.icon }} </mat-icon>
                {{ item.title }}
              </a>
            }
          </ng-container>
        </mat-nav-list>
      </mat-sidenav>
      <mat-sidenav-content role="main" style="padding: 5px">
        <router-outlet></router-outlet>
        <div #virtualKeyboardContainer [ngStyle]="{ 'height.px': virutalKeyboardHeight }"></div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
}

@if (!initialized) {
  <div class="stretch-max flex-align-center">
    <div class="flex-column flex-align-center gap-ten-px">
      <mat-label>{{ 'misc.initializing-krysskampen-message' | translate }}.</mat-label>
      <mat-progress-spinner mode="indeterminate"> </mat-progress-spinner>
    </div>
  </div>
}
