@if (loaded) {
  <div fxFlexFill>
    <div fxLayout.lt-sm="column" fxLayout.gt-xs="row" fxLayoutGap="10px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'labels.filter-crossword-games' | translate }}</mat-label>
        <input #filterInput matInput (keyup)="applyFilter(filterInput.value)" autocomplete="off" />
      </mat-form-field>
    </div>
    <mat-table
      id="list-crossword-games-table"
      listenForResize
      (sizeChanged)="onSizeChanged($event)"
      (matSortChange)="onSortChange($event)"
      matSort
      #table
      [dataSource]="dataSource"
      matSortActive="name"
      matSortDirection="asc"
    >
      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef class="w-60"></mat-header-cell>
        <mat-cell class="w-60" *matCellDef="let data"
          ><mat-icon>{{ data.playerCount > 1 ? 'people' : 'person' }}</mat-icon></mat-cell
        >
      </ng-container>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'columns.crossword' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let data">{{ data.name }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef class="w-100" mat-sort-header>{{ 'columns.last-action' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let data" class="w-100">{{ data.date | date: 'yyyy-MM-dd' }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="completedPercentage">
        <mat-header-cell *matHeaderCellDef class="w-60" mat-sort-header>{{ 'columns.completed-percentage' | translate }}</mat-header-cell>
        <mat-cell class="w-60" *matCellDef="let data">{{
          'columns-value.completed-percentage' | translate: { completedPercentage: data.completedPercentage }
        }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="score">
        <mat-header-cell *matHeaderCellDef class="w-60" mat-sort-header>{{ 'columns.score' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let data" class="w-60">{{ data.score }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="currentTurnPlayerName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'columns.turn' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let data">{{ data.currentTurnPlayerName }}</mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
      <mat-row
        *matRowDef="let data; columns: columns"
        [ngClass]="{ hovered: data.hovered }"
        (click)="onCrosswordGameSelected(data)"
        (mouseover)="data.hovered = true"
        (mouseout)="data.hovered = false"
        (blur)="data.hovered = false"
        (focus)="data.hovered = true"
      >
      </mat-row>
    </mat-table>
    <mat-paginator
      [ngStyle]="{ display: !loaded || dataSource.data.length == 0 ? 'none' : 'block' }"
      [pageSizeOptions]="[10, 50, 100]"
    ></mat-paginator>
  </div>
}
@if (!loaded) {
  <app-progress-bar [height]="200"></app-progress-bar>
}
