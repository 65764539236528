import { PriorityValue } from '../../data/priority.value';
import { BuildMatrix, BuildQuestion } from './matrix';
import { RandomizeUtil } from './randomize.util';
export class RemoveQuestions {
  constructor() {}
  public remove(questionsCount: number, matrix: BuildMatrix, removeThemeQuestions: boolean) {
    const questionsToRemove = RandomizeUtil.shuffle(matrix.getQuestions())
      .filter((q) => this.shouldRemoveQuestion(removeThemeQuestions, q))
      .slice(0, questionsCount);
    questionsToRemove.forEach((q) => matrix.removeQuestion(q.indexId));
    return questionsToRemove;
  }
  public removeOneQuestion(matrix: BuildMatrix, removeThemeQuestions: boolean) {
    const questionToRemove = RandomizeUtil.shuffle(matrix.getQuestions()).filter((q) =>
      this.shouldRemoveQuestion(removeThemeQuestions, q)
    )[0];
    return matrix.removeQuestion(questionToRemove.indexId);
  }
  private shouldRemoveQuestion(removeThemeQuestions: boolean, question: BuildQuestion) {
    if (removeThemeQuestions) {
      return true;
    } else {
      return question.priority != PriorityValue.Theme;
    }
  }
}
