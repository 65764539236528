import { GenerateCrosswordData } from '../../data/generate.crossword.data';
import { PriorityValue } from '../../data/priority.value';
import { BuildMatrix } from './matrix';
import { PositionAndQuestion } from './position.and.question';
import { QuestionsContainer } from './questions.container';

export class AddQuestionsToPosition {
  constructor(
    private readonly settings: GenerateCrosswordData,
    private readonly positionAndQuestion: PositionAndQuestion,
    private readonly questionsContainer: QuestionsContainer
  ) {}
  public addQuestionsToFreePositions(matrix: BuildMatrix, addQuestionsFrom: PriorityValue) {
    const questionsToFit = this.getQuestionsToFit(addQuestionsFrom, matrix);
    const numberOfQuestions = this.getNumberOfQuestions(addQuestionsFrom);
    for (let i = 0; i < numberOfQuestions; i++) {
      const openIntervals = matrix.getOpenIntervals();
      const positionAndData = this.positionAndQuestion.getPositionAndQuestion(questionsToFit, openIntervals);
      if (!positionAndData) {
        break;
      }
      questionsToFit.splice(questionsToFit.indexOf(positionAndData.question), 1);
      matrix.addQuestion(positionAndData.position, positionAndData.question);
    }
    return matrix;
  }
  private getQuestionsToFit(addQuestionsFrom: PriorityValue, matrix: BuildMatrix) {
    return this.questionsContainer.getQuestionsToFit(matrix, addQuestionsFrom, this.settings.accuracy.addQuestions.questionsCountPerRound);
  }
  private getNumberOfQuestions(addQuestionsFrom: PriorityValue) {
    const addQuestionSettings = this.settings.accuracy.addQuestions;
    return addQuestionsFrom != PriorityValue.Theme ? addQuestionSettings.random : addQuestionSettings.theme;
  }
}
