import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { GenerateQuestions } from '../../../../../Common/data/generate.questions';
import { QuestionAndAnswer } from '../../../../../Common/data/question.and.answer';
import { AppSettingsService } from '../appsettings/app-settings.service';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root',
})
export class GenerateQuestionsService {
  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private appSettingsService: AppSettingsService,
  ) {}
  async generate(value: GenerateQuestions) {
    const routes = this.configService.getRoutes();

    return await lastValueFrom(
      this.http.post<QuestionAndAnswer[]>(this.appSettingsService.appSettings.apiurl + routes.generateQuestions, value),
    );
  }
}
